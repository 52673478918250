@import "../../../assets/styles/variables.scss";

.order-details-title {
    color: $str_light_blue;
    font-weight: bold;
    font-size: x-large;
}

.order-details-row>* {
    width: 30%;
}

.label {
    width: 15%;
}

.order-details-row .label {
    font-weight: bold;
}

.line {
    color: $str_light_blue;
    height: 4px !important;
    opacity: unset;
}

.actions {
    direction: rtl;
}

.resend-email-btn {
    background-color: $str_light_blue;
    font-size: inherit;
    width: 20%;
}

.cancel-btn,
.refund-btn {
    background-color: $str_red;
    font-size: inherit;
    width: 15%;
    border-color: $str_red;
}

.download-btn {
    background-color: $str_light_blue;
    font-size: inherit;
}

.set-shipping-info-btn {
    background-color: $str_light_blue;
    font-size: inherit;
}

.show-quality-score-btn,
.request-order-fingerprints-btn {
    background-color: $str_maire !important;
    border-color: $str_black !important;
    font-size: inherit;
    margin-right: auto;
}

.ship-date-picker {
    border: 1px solid #999 !important;
    border-radius: 0.25rem;
    width: 100%;
    padding: 8px 12px !important;
}

.tracking-numbers-tooltip {
    white-space: pre-wrap;
}

.get-location-link {
    margin-left: 5px;
}

.oati {
    margin-top: 1rem;
}

.edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 200px;
    background-color: rgba(3, 152, 212, 0.35);
    border: none;

    &:hover,
    &:focus {
        background-color: rgba(3, 152, 212, 0.35);
        border-color: rgba(3, 152, 212, 0.35);
        box-shadow: none;
    }
}

.edit {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.zip-code{
    width:48%;
}

.pencil-icon-color {

    color: rgba(3, 152, 212, 1);
}