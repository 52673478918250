@import "../../../assets/styles/variables.scss";

.retention-setting {
    display: flex;
    position: relative;
    align-items: center;
    color: $str_dark_gray;
    padding-top: 20px;
    overflow: hidden;
}

.retention-value {
    background: $str_setting_light_gray;
    min-width: 65px;
    width: fit-content;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    position: absolute;
    left: 60%;
    border-radius: 5px;
    padding: 5px;
}

.retention-key {
    font-size: 17px;
}

.edit-setting-btn {
    float: right;
    width: 100px;
    border-radius: 10px;
    background-color: $str_light_blue;
    border-color: $str_light_blue;
}

.card-container {
    padding: 30px;
}

.retention-title {
    color: $str_dark_gray;
   
}
.retention-input{
        .input-control{
            padding: 8px 8px 8px 12px !important;
        }
}