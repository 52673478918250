@import "../../../assets/styles/variables.scss";

.user-accordion {
    width: 300px;
    margin-left: 4%;
    border-radius: 10px !important;
    text-align: center;

    .accordion-item {
        border: none !important;
    }

    .accordion-button {
        height: 20px;
        background-color: $str_gray_background !important;
        border-radius: 10px !important;
    }
}

.navbar-toggler-icon {
    background-image: url("../../../assets/images/bars.svg") !important;
}

.container-fluid {
    justify-content: unset !important;
}

.page-title {
    width: 70%;
    text-align: center;
    font-size: larger;
    font-weight: bold;
}
