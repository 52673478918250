@import "../../../assets/styles/variables.scss";

.grid-header {
  background-color: $str_light_blue !important;
  color: $str_white;
  display: flex;
  border-radius: 5px;
  height: 75px;
  padding: 1rem 1.25rem;
}
.grid-header > * {
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.grid-row {
  display: flex;
  flex: 1 0;
  overflow: hidden;
}
.grid-row > * {
  font-size: large;
  color: $str_grid_font_color;
}

.circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.status-col {
  display: flex;
}

.name-col {
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-message {
  font-size: small;
  color: $str_red;
}

.delivery-icon{
  padding-left: 5%;
}
@media only screen and (max-width: 850px) {
  .grid-header {
    display: none;
  }

  .hidden-in-responsive {
    display: none;
  }

  .order-item {
    margin-bottom: 10px;
  }

}
.order-checkbox{
  display: flex;
  align-items: flex-end;
}