.loader-wrapper {
    opacity: 0.5;
    background: rgb(184, 183, 183);
    width: 100%;
    height: 220vh;
    z-index: 1255;
    top: 0;
    left: 0;
    position: fixed;
}

.progress {
    margin-left: 28% !important;
    margin-top: 18% !important;
    width: 800px;
}