@import "../../../../assets/styles/variables.scss";

.auto-complete-select__control {
    border: 1px solid rgb(61, 185, 235) !important;
    color: $str_light_gray;
    border-radius: 7px;
}

.auto-complete-select__menu {
    z-index: 3 !important;
}

.auto-complete-select__option {
    font-size: 0.85rem !important;
}

.auto-complete-select__option--is-selected {
    background-color: rgb(61, 185, 235) !important;
}

.auto-complete-select__multi-value {
    font-size: 12px !important;
}