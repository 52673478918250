@import "../../../assets/styles/variables.scss";

.registeration-row {
  min-width: 90%;
}

.sub-title {
  color: $str_black;
}

.registeration-form {
  border: solid 3px $str_light_blue;
  background-color: $str_white;
  border-radius: 10px;
  padding-left: 80px !important;
  padding-right: 80px !important;
}
