@import "../../../assets/styles/variables.scss";

.grid-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: $str_grid_font_color;
  background-color: $str_white;
  border: 0.25px solid $str_gray_border;
  height: 5rem;
}

.is-active {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: $str_active_status_color;
  margin-left: 10px;
}

.inactive {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: $str_light_gray;
  margin-left: 10px;
}

.is-pending {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-color: $str_pending_status_color;
  margin-left: 10px;
}

.name-col {
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-message {
  font-size: small;
  color: $str_red;
}

.admin-identifier {
  background-color: $str_gray_border;
  color: $str_grid_font_color;
  margin-left: 0.5rem;
  border-radius: 10px;
  font-size: 0.8rem;
}

.user-options .btn-primary.dropdown-toggle {
  background-color: transparent;
  border: none;
  max-height: 50px;
}

.user-options .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.ellipse-7 {
  width: 3px;
  height: 3px;
  background-color: $str_light_blue;
  margin: 2px;
  border-radius: 5px;
}

.user-options .dots {
  align-items: center;
  color: $str_grid_font_color;
}

@media only screen and (max-width: 850px) {
  .grid-header {
    display: none;
  }

  .hidden-in-responsive {
    display: none;
  }

  .user-item {
    margin-bottom: 10px;
  }

  .input-search {
    width: 40%;

    input {
      width: 40%;
    }
  }
}

.icon {
  margin-right: 1rem;
  font-size: 21px;
  cursor: pointer;
  }
.delete-icon{
  color: $str_delete_red;
}
.edit-icon{
  color: $str_light_blue;

}
.edit-row{
  margin-bottom: 1.5rem;
}
.edit-label{
  align-items: center;
  font-size: 16px;
  display: flex;
}

.edit-modal-label{
  font-size: 18px;
  margin-bottom: 1rem;
  font-weight: bolder;
}
.select-role{
  margin-top: 2rem;
  align-items: center;
}