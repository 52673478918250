@import "../styles/variables.scss";

body {
  background-color: rgb(239 239 239) !important;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Helvetica !important;
}
.normal-font {
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
}
.modal-header {
  border-bottom: 0px !important;
}
.modal-footer {
  border-top: 0px !important;
}
.btn-close {
  background-color: $str_white !important;
  border-radius: 1.25rem !important;
  opacity: 0.5 !important;
  width: 10px !important;
  height: 10px !important;
  background-size: 1em 10px !important;
  margin-right: 1px !important;
}
.modal-btn-cancel {
  color: $str_light_blue !important;
  background-color: $str_white !important;
  border: 1px solid $str_light_blue !important;
  width: 10vw;
}
.modal-btn-save {
  background-color: $str_light_blue !important;
  color: $str_white;
  border: 1px solid $str_light_blue !important;
  width: 12vw;
}
.error-msg {
  color: red;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  margin: 0px;
  text-overflow: ellipsis;
}

.input-wrapper {
  background-color: $str_white;
  border: 1px solid $str_light_blue;
  border-radius: 7px;
  color: $str_light_gray;
}
.input-wrapper > input {
  color: $str_light_gray;
  border: none;
  outline: none;
  box-shadow: none;
  padding-inline-start: 5px;
  width: 90%;
}

.submit-btn {
  background-color: $str_light_blue !important;
  border-color: $str_light_blue !important;
  width: 30%;
}

.container-form-center {
  display: flex;
  justify-content: center;
}
.title {
  color: $str_light_blue;
  font-size: xx-large;
}
.input-control {
  background-color: $str_white !important;
  padding: 8px 30px 8px 12px !important;
  border: 1px solid $str_light_gray !important;
}
.top-bar {
  padding: 20px 48px;
  border-bottom: 1px solid #cccccc;
}
.back-to-login{
  font-size: 18px;
  float: right;
  margin-top: 2rem;
  text-decoration: underline;
  color: $str_light_blue;
  cursor: pointer;
}