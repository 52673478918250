@import "../../../../assets/styles/variables.scss";

.modal-header {
    color: $str_light_blue;
}
.modal-btn-save{
    width: 15rem;
}
.modal-btn-cancel{
    width: 10rem;
}