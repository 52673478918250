@import "../../../assets/styles/variables.scss";

.verify-card {
    border: solid 1px $border_light_blue;
    border-radius: 4px;
    background-color: white;
    padding: 1rem;
    display: grid;

    hr {
        width: 97%;
        justify-self: center;
    }

}

.bold-text {
    font-weight: bold;

}

.generator-div {
    background-color: $str_background_light_blue;
    width: 60%;
    justify-self: center;
    border-radius: 4px;
    border: solid 1px $str_dark_gray;
    padding: 1rem;
}

.title {
    justify-self: center;
    font-size: 25px;
}

.card-body {
    padding-left: 2rem;
}
.auth-radio{
    padding-left: 0.5rem;
    input{ 
        float: right !important

    }
  
}

.verify-input-control{
    width: 100%;
    margin: 1rem 0 0.4rem 0;
}
.continue{
    width: 12%;
    justify-self: center;
    margin: 2rem;
    background-color: $str_light_blue;
    border-color: $str_light_blue ;
}
.verify-next{
    width: 25%;
    justify-self: center;
    margin-top: 2rem;
    background-color: #0398d4;
    border-color: #0398d4;
    float: right;
}
.send-code{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-decoration: underline;
    color: $str_light_blue;
    cursor: pointer;
}
.dimmed{
    cursor: default;
    color: $str_dark_gray
}
.resend{
    width: 28%

}

.recaptcha{
    display: none;
}

.header-container{
    margin-bottom: 2rem;
}
.resend-code-container{
    display: flex;
    margin: 1.7rem 0rem 0.4rem 0rem;

}