@import "../../../assets/styles/variables.scss";

.grid-header {
  background-color: $str_light_blue !important;
  color: $str_white;
  display: flex;
  border-radius: 5px;
  height: 75px;
  padding: 1rem 1.25rem;
}

.grid-header>* {
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.accounting-grid-row {

  padding: 1rem 1.25rem !important;
  display: flex;
  flex: 1 0;
  overflow: hidden;
  background-color: $str_white;
  border: 0.25px solid $str_gray_border;
  height: 5rem;
}

.grid-row>* {
  font-size: large;
  color: $str_grid_font_color;
}

.circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.status-col {
  display: flex;
}

.name-col {
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-message {
  font-size: small;
  color: $str_red;
}

.accounting-date-range-picker {
  border: 1px solid $border_light_blue !important;
  width: 100%;
}

.export-csv {
  margin-left: auto !important;
  background-color: $str_light_blue;
  border-color: $str_light_blue;
}

.total-fees {
  background-color: $str_gray_border;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

.total-fees-label {
  color: $str_light_blue;
}
.select-status{
  width: 100%;
}
.accounting-export-col{
 div {
  button{
    width: 100%;
  }
 }
}