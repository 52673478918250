.loader-wrapper {
  opacity: 0.5;
  background: rgb(184, 183, 183);
  width: 100%;
  height: 220vh;
  z-index: 1255;
  top: 0;
  left: 0;
  position: fixed;
  direction: initial;
}

.spinner-border {
  margin-left: 48% !important;
  margin-top: 18% !important;
}
