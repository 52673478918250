@import "../../../assets/styles/variables.scss";

.box {
    background-color: white;
    border-radius: 15px;
}

.recharts-surface {
  padding: 10px;
}
.axis-lable {
  fill: $str_light_blue;
}

.theme-color{
  color : $str_light_blue;
}

.chart-dimentions .recharts-surface{
    margin: 50px 5px 15px 5px;
    width: 600px;
    height: 500px;
}
