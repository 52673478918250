@import "../../../../assets/styles/variables.scss";

.score-bar-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.score-bar {
    flex: 1;
    height: 10px;
    margin-right: 1px;
    margin-left: 1px;
    border: 1px solid $str_light_gray;
    border-radius: 5px;
}