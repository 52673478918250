@import "../../../assets/styles/variables.scss";

.form {
  border: solid 3px $str_light_blue;
  background-color: white;
  border-radius: 10px;
}

.login-btn {
  background-color: $str_light_blue !important;
  border-color: $str_light_blue !important;
  width: 30%;
}

.title {
  color: $str_light_blue;
  font-size: xx-large;
}

.top-bar {
  padding: 20px 48px;
  border-bottom: 1px solid #cccccc;
}

.container-wrapper {
  display: flex;
  justify-content: center;
}

.form-row {
  width: 45%;
}


.forget-password-link {
  float: right;
  text-decoration: unset;
}

@media only screen and (max-width: 850px) {
  .container {
    height: 100vh;
    background-color: white;
  }

  .top-bar {
    border-bottom: none;
    padding-top: 100px;
  }

  .form {
    border: none;
    border-radius: none;
    background-color: white;
  }

  .form-row {
    width: 100%;
  }

  .title {
    font-size: large;
    font-weight: bold;
  }

  .header-container-gap {
    gap: 0rem !important;
  }

  .form-label {
    font-size: small;
    color: #696868;
  }

  .input-control {
    border: 1px solid #cccaca !important;
  }

  .login-btn {
    width: 85%;
  }
}