@import "../../../assets/styles/variables.scss";

.location-grid-header {
    background-color: #0398d4 !important;
    color: #fff;
    display: flex;
    border-radius: 5px;
    height: 90px;
    padding: 1.5rem 0;
    align-items: center;

    .col {
        display: flex;
        flex: 1 0;
        justify-content: center;
        text-align: center;
    }

}


.location-grid-header>* {
    font-size: large;
    font-weight: bold;
    cursor: pointer;
}

.location-grid-row {

    padding: 1rem 0.2rem !important;
    display: flex;
    flex: 1 0;
    overflow: hidden;
    background-color: $str_white;
    border: 0.25px solid $str_gray_border;
    height: 5rem;

    .col {
        flex: 1 0;
        display: flex;
        justify-content: center;
        text-align: center;

    }
}

.location-grid-row>* {
    font-size: large;
    color: $str_grid_font_color;
}

.location-date-range-picker {
    border: 1px solid $border_light_blue !important;
    width: 100% !important;
}
.sort-icon{
    padding: 5px 0px 0px 3px;
}

.location-export-col{
    div {
     button{
       width: 100%;
     }
    }
    }
   