@import "../../../assets/styles/variables.scss";

.nav-sidemenu {
    margin-left: 4%;
}
.nav-item {
    border: solid 1px $str_gray_border;
    background-color: $str_white;
    border-radius: 10px;
    height: 60px;
    width: 250px;
}

.nav-item a {
    text-decoration: none;
}

.nav-link {
    padding: 0.5rem 1rem !important;
    height: 60px;
    width: 250px;
}
.tab-text {
    color: $str_black !important;
    padding: 5%;
}
.tab-text-icon {
    vertical-align: -webkit-baseline-middle;
    font-size: large;
}
.nav-link.active {
    background-color: $str_light_blue !important;
    .tab-text-icon,
    .tab-text {
        color: $str_white !important;
    }
}
