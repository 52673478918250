@import "../../../assets/styles/variables.scss";

.two-factor-auth {
    background-color: $border_light_blue;
    padding: 30px;
    border-radius: 10px;
    margin: 20px;
    color: white;
    font-size: 20px;
    cursor: pointer;


    a{
        text-decoration: none;
        color: white;
    }
    span{
        color: white;
    }
}

.icon-2fa {
    right: 3%;
    position: absolute;
}

.two-factor-auth-header {
    font-size: 16px;
    margin: 1rem;

}

.back-btn {
    color: $border_light_blue;
    font-size: 18px;
    cursor: pointer;
}

.select-security-method {
    color: $str_light_blue;
    font-size: 16px;
    font-weight: bold;
    margin: 0 267px 15px 0;

}

.two-factor-auth-text {
    color: $str_black;
    margin-top: 0.7rem;


}

.security-selection {
    background-color: white;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    width: 90%;
    justify-self: center;
}

.auth-body {
    margin: 10px 0 22px;
    font-size: 14px;

}

.auth-title {
    color: $str_light_blue;
    font-size: 16px;
    font-weight: bold;
}
.auth-card{
    padding: 18px 12px 25px 20px;
    min-height: 185px;


}
.auth-btn{
    background-color: $str_light_blue;
    border-color: $str_light_blue;
    position: absolute;
    width: 55%;
    left: 43%;
    height: 42px;
    top: 72%;
    font-size: 16px;
}

.instruction-list{
    color: $str_light_blue;
}
.instruction-list-text{
    margin-top: 10px;
    font-size: 14px;
}
.qr-code{
    margin: 1rem;
}
.auth-modal{
   font-weight: bold; 
}
.otp-input-control{
    width: 99%;
}
.selected-security-method{

    background-color: white;
    padding: 2rem;
    margin: 1.5rem;
    border-radius: 6px;
}

.selected-method-title{
    font-weight: bold;
    margin-bottom: 2rem;
    
}
.turn-off{
    background-color: white;
    border: solid 1px $str_light_blue;
    color: $str_light_blue;
    width: 27%;
    left: 70%;
   
}
.turn-off:hover{
    background-color: $str_light_blue;
    border: solid 1px $str_light_blue;

}

.phone-number-action{
    border: solid 1px $str_gray_border;
    padding: 1rem;
}

.copy-key{
    text-decoration: underline;
    color: #007bff;
    padding-left: 1rem;
    cursor: pointer;
}
.copied{
    text-decoration: none;
    color: $str_black;
    font-weight: 500;
    padding-left: 1rem;

}
.bold-text{
    font-weight: bold;
}
.verify-modal-body{
    margin-top: -20px;
    white-space: pre-line;
    line-height: 1.4;
}
.resend-container{
    display: flex;
    margin: 0.5rem 0rem 0.4rem 0rem;

}
.link{
    text-decoration: none;
}