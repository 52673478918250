$str_light_blue: #0398d4;
$str_light_gray: #999;
$str_white: #fff;
$str_grid_font_color: darkslategrey;
$str_red: red;
$str_gray_border: #d2d2d2;
$str_gray_background: #f0eded;
$str_black: #000;
$str_maire: #1f1f1e;
$str_active_status_color: #25b825;
$str_pending_status_color: rgb(238, 182, 60);
$border_light_blue: rgb(61, 185, 235);
$str_green:#5AA913;
$str_dark_gray: #777777;
$str_setting_light_gray: #e9ecee;
$str_background_light_blue: #e8f0fe;
$str_delete_red: #FF5C5C