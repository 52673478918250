@import "../../../assets/styles/variables.scss";

.services {
    padding-top: 20px;
}

.service-row {
    padding-top: 10px;

    .service-label {
        color: $str_dark_gray;
    }

    .service-value {
        font-size: 14px;
    }
}

.service-logo {
    width: 35px;

}

.drag-icon-div {
    text-align: right;
}

.drag-icon {
    color: $str_light_blue;
    position: relative;
    top: calc(50% - 10px); //to center vertically
}

.save-services-btn {
    float: right;
    margin-left: 20px;
    width: 100px;
    border-radius: 10px;
    background-color: $str_light_blue;
    border-color: $str_light_blue;
}

.cancel-services-btn{
    float: right;
    background-color: white;
    border-color: $str_light_blue;
    color: $str_light_blue;
    border-radius: 10px;
    width: 100px;
}