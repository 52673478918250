@import "../../../../assets/styles/variables.scss";

.pagination-container {
  display: flex;
  justify-content: center;
}

.paginate {
  background-color: rgb(239 239 239) !important;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

a {
  color: $str_light_blue !important;
}
