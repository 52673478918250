.quality-score-item {
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 8vh;
}

.quality-score-item-position {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spinner-ml-0 {
    margin-left: 0 !important;
}

.quality-score-item-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.quality-score-item-score,
.quality-score-item-quality {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}